












import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { UserContactResponse } from './LmsContactOverlay.vue';

const CommonProfileImage = () => import('common-modules/src/components/CommonProfileImage.vue');

const props = Vue.extend({
  props: {
    contactPerson: Object as PropType<UserContactResponse>,
  },
});

@Component({
  components: {
    CommonProfileImage,
  },
})
export default class LmsContactProfile extends props {}
